import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useClickAway } from '@uidotdev/usehooks';
import { StaticImage } from 'gatsby-plugin-image';

import BIIcon from '@components/BIIcon';
import Megamenu from '@components/Megamenu';
import useHeader from '@hooks/useHeader';
import classNames from 'classnames';
import Link from '@components/Link';
import useQuelixSearch from '@hooks/useQuelixSearch';
import { getValueNull } from '@src/utils/utilsJavascript';

const Header = () => {
  const headerHook = useHeader();
  const socials = headerHook?.length > 0 ? headerHook[0] : {};
  const titleFacebook = getValueNull(socials, 'field_facebook.title', '');
  const uriFacebook = getValueNull(socials, 'field_facebook.uri', '');
  const titleInstagram = getValueNull(socials, 'field_instagram.title', '');
  const uriInstagram = getValueNull(socials, 'field_instagram.uri', '');
  const titleYoutube = getValueNull(socials, 'field_youtube.title', '');
  const uriYoutube = getValueNull(socials, 'field_youtube.uri', '');
  const titleX = getValueNull(socials, 'field_x.title', '');
  const uriX = getValueNull(socials, 'field_x.uri', '');
  const titleLinkedin = getValueNull(socials, 'field_linkedin.title', '');
  const uriLinkedin = getValueNull(socials, 'field_linkedin.uri', '');
  const titleTelegram = getValueNull(socials, 'field_telegram.title', '');
  const uriTelegram = getValueNull(socials, 'field_telegram.uri', '');

  const query = useStaticQuery(graphql`
    query {
      nodePage(field_gatsby_template: { eq: "ricerca" }) {
        path {
          alias
          langcode
        }
      }
    }
  `);

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const inputGroupRef = useClickAway(() => setIsSearchOpen(false));
  const modalRef = useRef(null);

  const { search, results } = useQuelixSearch({
    params: { num: 10 },
    filter: [{ 'gatsby:title': '' }]
  });

  const closeModal = () => setIsSearchOpen(false);

  const handleBlur = (event) => {
    setTimeout(() => {
      if (
        modalRef.current &&
        !modalRef.current.contains(document.activeElement)
      ) {
        closeModal();
      }
    }, 100);
  };

  const onSearchButton = () => {
    const params = new URLSearchParams();
    params.set('cerca', searchQuery);

    const searchUrl = `/${query.nodePage.path.langcode}${
      query.nodePage.path.alias
    }?${params.toString()}`;

    navigate(searchUrl);
    setIsSearchOpen(false);
  };

  return (
    <header className='it-header-wrapper'>
      <div className='it-header-slim-wrapper'>
        <div className='container'>
          <div className='it-header-slim-wrapper-content'>
            <Link
              to='https://www.governo.it/it'
              className='navbar-brand'
              tabIndex={0}
              target='_blank'
              rel='noreferrer'
              aria-label='Governo Italiano - Sito esterno - Apre in una nuova finestra'
            >
              Governo Italiano
            </Link>
          </div>
        </div>
      </div>
      <div className='it-nav-wrapper'>
        <div className='it-header-navbar-wrapper small-screen'>
          <div className='container'>
            <Megamenu mobileVersion={true} />
          </div>
        </div>
        <div className='it-header-center-wrapper'>
          <div className='container'>
            <div className='it-header-center-content-wrapper'>
              <div className='it-brand-wrapper'>
                <Link to='/' aria-label='Ministero della Salute'>
                  <StaticImage
                    className='logo-repubblica'
                    src='../images/icons/Stemma_Repubblica_Italiana.svg'
                    alt='logo-repubblica'
                  />
                  <div className='it-brand-text'>
                    <div className='it-brand-title' tabIndex={-1}>
                      Ministero della Salute
                    </div>
                  </div>
                </Link>
              </div>
              <div className='ms-auto search-responsive'>
                <div
                  onClick={(e) => !e.shiftKey && setIsSearchOpen(true)}
                  onKeyDown={(e) => !e.shiftKey && setIsSearchOpen(true)}
                  role='button'
                  tabIndex={0}
                  aria-label='Cerca'
                  className='marginAuto'
                >
                  <BIIcon name='search' color='white' size='sm' />
                </div>
              </div>
              <div className='it-right-zone gap80'>
                <div className='it-socials d-none d-lg-flex'>
                  <span>Seguici su</span>
                  <ul>
                    <li>
                      <Link
                        to={uriFacebook}
                        aria-label={`Facebook - Sito esterno - Apertura in una nuova finestra`}
                      >
                        <BIIcon name='facebook' alt={'Facebook'} />
                      </Link>
                    </li>
                    <li>
                      {/* SU BOOTSTRAP ITALIA L'ICONA DI X E' AGGIORNATA GRAFICAMENTE MA INSERENDOLA ANCORA RENDERIZZA QUELLA DI TWITTER, PER IL MOMENTO HO INSERITO QUELLA DI BOOTSTRAP*/}
                      <Link
                        to={uriX}
                        aria-label={`X - Sito esterno - Apertura in una nuova finestra`}
                        title='X'
                        className='ms-3'
                      >
                        {/* <BIIcon name='twitter' alt={'X'} /> */}
                        <svg
                          width='17'
                          height='17'
                          fill='white'
                          className='bi bi-twitter-x '
                          viewBox='0 0 16 16'
                          title='X'
                        >
                          <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z' />
                          <title>X</title>
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={uriInstagram}
                        aria-label={`Instagram - Sito esterno - Apertura in una nuova finestra`}
                      >
                        <BIIcon name='instagram' alt={'Instagram'} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={uriTelegram}
                        aria-label={`Telegram- Sito esterno - Apertura in una nuova finestra`}
                      >
                        <BIIcon name='telegram' alt={'Telegram'} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={uriLinkedin}
                        aria-label={`Linkedin - Sito esterno - Apertura in una nuova finestra`}
                      >
                        <BIIcon name='linkedin' alt={'Linkedin'} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={uriYoutube}
                        aria-label={`Youtube - Sito esterno - Apertura in una nuova finestra`}
                      >
                        <BIIcon name='youtube' alt={'Youtube'} />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to='/it/sezione/rss' aria-label='RSS'>
                        <BIIcon name='rss' alt={'Rss'} />
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className='it-socials d-none d-lg-flex gap16'>
                  <div>Cerca</div>
                  <div
                    onClick={(e) => !e.shiftKey && setIsSearchOpen(true)}
                    onKeyDown={(e) => !e.shiftKey && setIsSearchOpen(true)}
                    aria-label='Cerca'
                    role='button'
                    tabIndex={0}
                    className='d-flex bg-white rounded-circle align-items-center justify-content-center widthHeight'
                  >
                    <BIIcon name='search' color='primary' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Overlay
        className={classNames('overlay-panel overlay-panel-fullheight', {
          'd-none': !isSearchOpen
        })}
      />
      <Modal open={isSearchOpen}>
        <div
          className='d-flex flex-column mx-3 mt-2 mx-md-0 mt-lg-4'
          ref={modalRef}
          tabIndex={-1}
          onBlur={handleBlur}
          onFocusOut={handleBlur}
        >
          <div className='d-flex flex-grow justify-content-end me-3 mb-2 mb-lg-3'>
            <button
              type='button'
              onClick={() => setIsSearchOpen(false)}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' ||
                  (event.key === ' ' && isSearchOpen)
                ) {
                  setIsSearchOpen(false);
                }
              }}
              aria-label='Chiudi il box di ricerca'
              className='btn-close-icon'
              title='Chiudi il box di ricerca'
            >
              <BIIcon name='close-circle' size='lg' color='white' />
            </button>
          </div>

          <div
            ref={inputGroupRef}
            className='input-group input-group-lg bg-white'
          >
            <div className='input-group-prepend'>
              <span
                className='input-group-text ps-2 pe-0 border-bottom-0'
                id='inputGroup-sizing-lg'
              >
                <BIIcon name='search' size='sm' />
              </span>
            </div>
            <input
              aria-label='Cerca'
              onChange={(event) => {
                setSearchQuery(event.target.value);
                search(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') onSearchButton();
              }}
              className='autocomplete form-control ps-2 border-bottom-0 headerHeight'
              type='search'
              placeholder='Testo da cercare'
            />
            <ul
              className={classNames('autocomplete-list top-100', {
                'd-block': results.length > 0
              })}
            >
              {results.map((result, index) => (
                <li key={index}>
                  <Link
                    to={
                      process.env.GATSBY_ENABLE_STAGE_REWRITE === 'true'
                        ? result?.U.replace(
                            'www.salute.gov.it',
                            'stage.salute.gov.it'
                          )
                        : result?.U
                    }
                  >
                    <span className='autocomplete-list-text'>
                      <span>
                        {result?.MT?.['gatsby:title']?.join(', ') || result?.T}
                      </span>
                      <em>
                        {result?.MT?.['gatsby:page-type']?.[0] ??
                          result?.MT?.['gatsby:node-type']?.[0]}
                      </em>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className='input-group-append'>
              <div className='input-group-text border-bottom-0'>
                <button
                  type='button'
                  className='btn btn-primary h-50 rounded'
                  onClick={onSearchButton}
                >
                  Cerca
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className='it-header-navbar-wrapper big-screen'>
        <div className='container'>
          <Megamenu mobileVersion={false} />
        </div>
      </div>
    </header>
  );
};

const Overlay = styled.div`
  z-index: 10;
`;

const Modal = styled.div`
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 668px;
  }
`;

export default Header;
