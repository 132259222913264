module.exports = {
  url: process.env.GATSBY_QUELIX_URL,
  site: process.env.GATSBY_QUELIX_SITE,
  client: process.env.GATSBY_QUELIX_CLIENT,
  defaultQuery: '-filetype:xml -filetype:svg',
  filter: [
    {
      '-gatsby:node-type': [
        'Pagina Speciale',
        'Section home',
        'Section Tabs',
        'Snodo menu',
        'Contenuti di Sezione',
        'Contenuti di Sezione Avvisi'
      ]
    }
  ]
};
