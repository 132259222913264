import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import parse from '@src/utils/parse';
import useUpperFooter from '@hooks/useUpperFooter';
import useLowerFooter from '@hooks/useLowerFooter';
import useFooterMenu from '@hooks/useFooterMenu';
import { StaticImage } from 'gatsby-plugin-image';
import ColonnaFooter from './ColonnaFooter';
import TemiAZLetters from '@components/TemiAZLetters';
import Link from '@components/Link';
import BIIcon from '@components/BIIcon';

import { getValueNull } from '@src/utils/utilsJavascript';
import useHeader from '@hooks/useHeader';

import './styles.css';

const Footer = () => {
  const headerHook = useHeader();
  const footerMenu = useFooterMenu();
  const socials = headerHook?.length > 0 ? headerHook[0] : {};
  const titleFacebook = getValueNull(socials, 'field_facebook.title', '');
  const uriFacebook = getValueNull(socials, 'field_facebook.uri', '');
  const titleInstagram = getValueNull(socials, 'field_instagram.title', '');
  const uriInstagram = getValueNull(socials, 'field_instagram.uri', '');
  const titleYoutube = getValueNull(socials, 'field_youtube.title', '');
  const uriYoutube = getValueNull(socials, 'field_youtube.uri', '');
  const titleX = getValueNull(socials, 'field_x.title', '');
  const uriX = getValueNull(socials, 'field_x.uri', '');
  const titleLinkedin = getValueNull(socials, 'field_linkedin.title', '');
  const uriLinkedin = getValueNull(socials, 'field_linkedin.uri', '');
  const titleTelegram = getValueNull(socials, 'field_telegram.title', '');
  const uriTelegram = getValueNull(socials, 'field_telegram.uri', '');

  return (
    <footer className='it-footer primary-bg-a8'>
      <div className='it-footer-main bottom py-3'>
        <div className='container '>
          <section className='py-3 row d-flex justify-content-between g-lg-0 '>
            <div className='row gap-1 gap-md-3 gap-lg-5'>
              <div className='col-12 col-md-3 d-flex align-items-center ps-lg-0 gap-2 gap-md-4 border-bottom border-md-right border-md-bottom-0 pb-4 pb-md-0 py-md-1'>
                <Link
                  to='https://commission.europa.eu/'
                  aria-label={`Commissione europea - Sito esterno - Apertura in una nuova finestra`}
                >
                  <StaticImage
                    src='../../images/icons/bandiera_europa.svg'
                    alt='bandiera-europa'
                  />
                </Link>
              </div>
              <div className='col-12 col-md-3 d-flex align-items-center ps-lg-0 gap-4 border-bottom border-md-right border-md-bottom-0 py-4 py-md-1'>
                <Link
                  to='/'
                  className=' text-white text-decoration-none d-flex align-items-center gap-3'
                  aria-label={`Ministero della salute - Sito esterno - Apertura in una nuova finestra`}
                >
                  <StaticImage
                    src='../../images/icons/logo_ministero.svg'
                    alt='logo-ministero'
                  />
                </Link>
                <span>Ministero Della Salute</span>
              </div>
              <div className='col-12 col-md-3 d-flex align-items-center ps-lg-0 justify-content-md-center gap-4 py-4 py-md-1'>
                <Link
                  to='https://www.italiadomani.gov.it/'
                  aria-label={`Italia domani - Sito esterno - Apertura in una nuova finestra`}
                >
                  <StaticImage
                    src='../../images/icons/logo_italia_domani.svg'
                    alt='logo-italia-domani'
                  />
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className='container'>
        <div className='it-footer-main'>
          <section className='py-4'>
            <div className='row upper'>
              {useUpperFooter().map((el) => {
                return (
                  <ColonnaFooter key={`ColonnaFooter-${el.id}`} column={el} />
                );
              })}
              <div className='col-12 col-lg-3 col-md-6 my-3 my-lg-0'>
                <TemiAZLetters dark={true} />
              </div>
            </div>
          </section>
          <section className='py-4 lower'>
            <div className='border-white border-top pt-5'>
              <div className='row'>
                {useLowerFooter().map((el, index) => (
                  <div
                    key={index}
                    id={`lower_footer_container_${index}`}
                    className='col-12 col-lg-3 col-md-6 custom my-3 my-lg-0  lower_footer '
                  >
                    {parse(el.node.body.processed)}
                  </div>
                ))}
                <div
                  key={`${uuidv4()}-${useLowerFooter().length - 1}`}
                  id='lower_footer_social_container'
                  className='col-12 col-lg-3 col-md-6 custom my-3 my-lg-0 lower_footer'
                >
                  <div className='h3'>SEGUICI SU</div>
                  <div id='social_icon_container' aria-label='Social Network'>
                    <ul>
                      <li>
                        <Link
                          to={uriFacebook}
                          aria-label={`Facebook - Sito esterno - Apertura in una nuova finestra`}
                        >
                          <BIIcon name='facebook' alt={'Facebook'} />
                        </Link>
                      </li>
                      <li className='marginTop2'>
                        {/* SU BOOTSTRAP ITALIA L'ICONA DI X E' AGGIORNATA GRAFICAMENTE MA INSERENDOLA ANCORA RENDERIZZA QUELLA DI TWITTER, PER IL MOMENTO HO INSERITO QUELLA DI BOOTSTRAP*/}
                        <Link
                          to={uriX}
                          aria-label={`X - Sito esterno - Apertura in una nuova finestra`}
                        >
                          {/* <BIIcon name='twitter' alt={'X'} /> */}

                          <svg
                            width='50'
                            height='50'
                            fill='white'
                            className='bi bi-twitter-x'
                            viewBox='0 0 20 20'
                          >
                            <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z' />
                            <title>X</title>
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={uriInstagram}
                          aria-label={`Instagram - Sito esterno - Apertura in una nuova finestra`}
                        >
                          <BIIcon name='instagram' alt={'Instagram'} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={uriTelegram}
                          aria-label={`Telegram - Sito esterno - Apertura in una nuova finestra`}
                        >
                          <BIIcon name='telegram' alt={'Telegram'} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={uriLinkedin}
                          aria-label={`Linkedin- Sito esterno - Apertura in una nuova finestra`}
                        >
                          <BIIcon name='linkedin' alt={'Linkedin'} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={uriYoutube}
                          aria-label={`${titleYoutube} - Sito esterno - Apertura in una nuova finestra`}
                        >
                          <BIIcon name='youtube' alt={'Youtube'} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <p
                    id='social_container_space'
                    className='social_container'
                    aria-label='Social Network'
                  >
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className='it-footer-main bottom'>
        <div className='container '>
          <section className='py-3 d-flex flex-row flex-wrap justify-content-space-evenly gap-3'>
            {Array.isArray(footerMenu) &&
              footerMenu
                .filter(
                  (u) =>
                    !!u?.url && u?.title.trim().toLowerCase() !== 'contatto'
                )
                .map(({ id, title, url }) => (
                  <div className='flex-1 fw-bold ' key={id}>
                    <Link
                      title={title}
                      to={url}
                      tabIndex='0'
                      className='text-white text-decoration-none'
                      target={url?.includes('http') ? '_blank' : '_self'}
                      aria-label={
                        url?.includes('http')
                          ? `${title} - Sito esterno - Apertura in una nuova finestra`
                          : `Collegamento a ${title}`
                      }
                    >
                      {title}
                    </Link>
                  </div>
                ))}
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
