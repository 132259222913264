import React from 'react';
import BIIcon from '@components/BIIcon';

const BackToTop = () => {
  return (
    <button
      type='button'
      data-bs-toggle='backtotop'
      className='back-to-top'
      id='back-to-top'
      aria-label='Torna a inizio pagina'
      title='Torna a inizio pagina'
    >
      <BIIcon name='arrow-up' className='icon' />
    </button>
  );
};

export default BackToTop;
