import { useStaticQuery, graphql } from 'gatsby';

const useFooterMenu = () => {
  const {
    FooterMenu: { nodes }
  } = useStaticQuery(
    graphql`
      fragment FooterMenuContent on menu_link_content__menu_link_content {
        id
        title
        url
        options {
          external
          attributes {
            to_side
          }
        }
        route {
          parameters {
            node
          }
        }
      }

      {
        FooterMenu: allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: { eq: "footer" }  
            drupal_parent_menu_item: { eq: "" }        
          }
        ) {
          nodes {
            ...MenuContent
            childMenuLinkContent {
              ...MenuContent
              childMenuLinkContent {
                ...MenuContent
              }
            }
          }
        }
      }
    `
  );
  return nodes;
};

export default useFooterMenu;
