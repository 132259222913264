import React, { createContext, useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { join } from 'path-browserify';
import useAllAliasPath from '@hooks/useAllAliasPath';
import { extractText, searchAlias } from '@src/utils/utilsJavascript';

export const LinkContext = createContext({ defaultParams: {} });

const Link = ({
  children,
  to = '#',
  ignoreParams = false,
  isAnagrafi = false,
  ...other
}) => {
  const context = useContext(LinkContext);
  const allAliasPath = useAllAliasPath();
  const getPath = (uri) => searchAlias(allAliasPath, uri);
  const presentParam = to?.includes('?tema=');

  const path = (/(entity:|internal:)/gm.test(to) ? getPath(to) : to) ?? '';
  const params = new URLSearchParams(context.defaultParams);

  const isInternal = /^\/(?!\/)/.test(path);
  const isFile = /^.*\.[^\\|\/]+$/.test(path);
  const isRelative = /^\/.*$/.test(path);

  const normalizedPath = Array.isArray(path) ? path.join('') : path;

  const href = isRelative
    ? join(process.env.GATSBY_PREFIX_PATH ?? '', normalizedPath)
    : path;

  const ariaLabel = !!other['aria-label']
    ? other['aria-label']
    : typeof path === 'string' && path.length > 0 && path.startsWith('http')
    ? typeof children === 'string'
      ? children + ' - Sito esterno - Apertura in una nuova finestra'
      : extractText(children)
    : typeof children === 'string'
    ? ' Collegamento a ' + children
    : extractText(children);

  if (isInternal && !isFile) {
    return (
      <GatsbyLink
        to={`${path}${
          params.size > 0 && !ignoreParams && !presentParam
            ? `?${params.toString()}`
            : ''
        }${isAnagrafi ? `&tipologia=anagrafe` : ''}`}
        aria-label={ariaLabel}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  if (!!other?.name)
    return (
      <a
        download={href?.slice(-3) === 'xsd'}
        href={href}
        aria-label={ariaLabel}
        {...other}
        name={other.name}
      >
        {children}
      </a>
    );
  return (
    <a
      download={href?.slice(-3) === 'xsd'}
      href={href}
      {...other}
      aria-label={ariaLabel}
      rel='noreferrer nopener'
      target={href?.startsWith('http') || isFile ? '_blank' : other?.target}
    >
      {children}
    </a>
  );
};

export default Link;
