import { useStaticQuery, graphql } from 'gatsby';

const useHeader = () => {
  const { allNodeSeodata } = useStaticQuery(graphql`
    query MyQuery {
      allNodeSeodata(limit: 1) {
        nodes {
          title
          field_facebook {
            title
            uri
          }
          field_instagram {
            title
            uri
          }
          field_linkedin {
            title
            uri
          }
          field_x {
            title
            uri
          }
          field_telegram {
            title
            uri
          }
          field_youtube {
            title
            uri
          }
        }
      }
    }
  `);
  return allNodeSeodata.nodes;
};

export default useHeader;
