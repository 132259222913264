exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-amministrazione-trasparente-jsx": () => import("./../../../src/templates/amministrazioneTrasparente.jsx" /* webpackChunkName: "component---src-templates-amministrazione-trasparente-jsx" */),
  "component---src-templates-avviso-aziende-farmaceutiche-jsx": () => import("./../../../src/templates/avvisoAziendeFarmaceutiche.jsx" /* webpackChunkName: "component---src-templates-avviso-aziende-farmaceutiche-jsx" */),
  "component---src-templates-avviso-dispositivi-medici-jsx": () => import("./../../../src/templates/avvisoDispositiviMedici.jsx" /* webpackChunkName: "component---src-templates-avviso-dispositivi-medici-jsx" */),
  "component---src-templates-avviso-ext-sicurezza-alimentare-jsx": () => import("./../../../src/templates/avvisoExtSicurezzaAlimentare.jsx" /* webpackChunkName: "component---src-templates-avviso-ext-sicurezza-alimentare-jsx" */),
  "component---src-templates-avviso-medicinali-veterinari-jsx": () => import("./../../../src/templates/avvisoMedicinaliVeterinari.jsx" /* webpackChunkName: "component---src-templates-avviso-medicinali-veterinari-jsx" */),
  "component---src-templates-avviso-richiami-operatori-jsx": () => import("./../../../src/templates/avvisoRichiamiOperatori.jsx" /* webpackChunkName: "component---src-templates-avviso-richiami-operatori-jsx" */),
  "component---src-templates-avviso-sicurezza-alimentare-jsx": () => import("./../../../src/templates/avvisoSicurezzaAlimentare.jsx" /* webpackChunkName: "component---src-templates-avviso-sicurezza-alimentare-jsx" */),
  "component---src-templates-avviso-sostanze-pericolose-jsx": () => import("./../../../src/templates/avvisoSostanzePericolose.jsx" /* webpackChunkName: "component---src-templates-avviso-sostanze-pericolose-jsx" */),
  "component---src-templates-banca-dati-jsx": () => import("./../../../src/templates/bancaDati.jsx" /* webpackChunkName: "component---src-templates-banca-dati-jsx" */),
  "component---src-templates-bando-avviso-jsx": () => import("./../../../src/templates/bandoAvviso.jsx" /* webpackChunkName: "component---src-templates-bando-avviso-jsx" */),
  "component---src-templates-bando-jsx": () => import("./../../../src/templates/bando.jsx" /* webpackChunkName: "component---src-templates-bando-jsx" */),
  "component---src-templates-campagna-jsx": () => import("./../../../src/templates/campagna.jsx" /* webpackChunkName: "component---src-templates-campagna-jsx" */),
  "component---src-templates-centri-collaboratori-jsx": () => import("./../../../src/templates/centriCollaboratori.jsx" /* webpackChunkName: "component---src-templates-centri-collaboratori-jsx" */),
  "component---src-templates-comunicato-jsx": () => import("./../../../src/templates/comunicato.jsx" /* webpackChunkName: "component---src-templates-comunicato-jsx" */),
  "component---src-templates-concorso-avviso-jsx": () => import("./../../../src/templates/concorsoAvviso.jsx" /* webpackChunkName: "component---src-templates-concorso-avviso-jsx" */),
  "component---src-templates-concorso-jsx": () => import("./../../../src/templates/concorso.jsx" /* webpackChunkName: "component---src-templates-concorso-jsx" */),
  "component---src-templates-evento-jsx": () => import("./../../../src/templates/evento.jsx" /* webpackChunkName: "component---src-templates-evento-jsx" */),
  "component---src-templates-faq-detail-jsx": () => import("./../../../src/templates/faqDetail.jsx" /* webpackChunkName: "component---src-templates-faq-detail-jsx" */),
  "component---src-templates-highlight-jsx": () => import("./../../../src/templates/highlight.jsx" /* webpackChunkName: "component---src-templates-highlight-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-medico-fiduciario-jsx": () => import("./../../../src/templates/medicoFiduciario.jsx" /* webpackChunkName: "component---src-templates-medico-fiduciario-jsx" */),
  "component---src-templates-ministero-jsx": () => import("./../../../src/templates/ministero.jsx" /* webpackChunkName: "component---src-templates-ministero-jsx" */),
  "component---src-templates-ministri-tabs-jsx": () => import("./../../../src/templates/ministriTabs.jsx" /* webpackChunkName: "component---src-templates-ministri-tabs-jsx" */),
  "component---src-templates-ministro-jsx": () => import("./../../../src/templates/ministro.jsx" /* webpackChunkName: "component---src-templates-ministro-jsx" */),
  "component---src-templates-monitoraggio-covid-jsx": () => import("./../../../src/templates/monitoraggioCovid.jsx" /* webpackChunkName: "component---src-templates-monitoraggio-covid-jsx" */),
  "component---src-templates-monitoraggio-jsx": () => import("./../../../src/templates/monitoraggio.jsx" /* webpackChunkName: "component---src-templates-monitoraggio-jsx" */),
  "component---src-templates-multimedia-detail-jsx": () => import("./../../../src/templates/multimediaDetail.jsx" /* webpackChunkName: "component---src-templates-multimedia-detail-jsx" */),
  "component---src-templates-notizia-jsx": () => import("./../../../src/templates/notizia.jsx" /* webpackChunkName: "component---src-templates-notizia-jsx" */),
  "component---src-templates-notizia-nas-jsx": () => import("./../../../src/templates/notiziaNas.jsx" /* webpackChunkName: "component---src-templates-notizia-nas-jsx" */),
  "component---src-templates-ondata-calore-jsx": () => import("./../../../src/templates/ondataCalore.jsx" /* webpackChunkName: "component---src-templates-ondata-calore-jsx" */),
  "component---src-templates-organigramma-jsx": () => import("./../../../src/templates/organigramma.jsx" /* webpackChunkName: "component---src-templates-organigramma-jsx" */),
  "component---src-templates-organismo-extra-ue-jsx": () => import("./../../../src/templates/organismoExtraUe.jsx" /* webpackChunkName: "component---src-templates-organismo-extra-ue-jsx" */),
  "component---src-templates-pagina-base-altro-jsx": () => import("./../../../src/templates/paginaBaseAltro.jsx" /* webpackChunkName: "component---src-templates-pagina-base-altro-jsx" */),
  "component---src-templates-pagina-non-disponibile-jsx": () => import("./../../../src/templates/paginaNonDisponibile.jsx" /* webpackChunkName: "component---src-templates-pagina-non-disponibile-jsx" */),
  "component---src-templates-prodotto-editoriale-jsx": () => import("./../../../src/templates/prodottoEditoriale.jsx" /* webpackChunkName: "component---src-templates-prodotto-editoriale-jsx" */),
  "component---src-templates-pubblicazione-jsx": () => import("./../../../src/templates/pubblicazione.jsx" /* webpackChunkName: "component---src-templates-pubblicazione-jsx" */),
  "component---src-templates-question-time-jsx": () => import("./../../../src/templates/questionTime.jsx" /* webpackChunkName: "component---src-templates-question-time-jsx" */),
  "component---src-templates-ricerca-jsx": () => import("./../../../src/templates/ricerca.jsx" /* webpackChunkName: "component---src-templates-ricerca-jsx" */),
  "component---src-templates-scheda-informativa-jsx": () => import("./../../../src/templates/schedaInformativa.jsx" /* webpackChunkName: "component---src-templates-scheda-informativa-jsx" */),
  "component---src-templates-scheda-malattia-jsx": () => import("./../../../src/templates/schedaMalattia.jsx" /* webpackChunkName: "component---src-templates-scheda-malattia-jsx" */),
  "component---src-templates-scheda-statistica-jsx": () => import("./../../../src/templates/schedaStatistica.jsx" /* webpackChunkName: "component---src-templates-scheda-statistica-jsx" */),
  "component---src-templates-section-content-avvisi-jsx": () => import("./../../../src/templates/sectionContentAvvisi.jsx" /* webpackChunkName: "component---src-templates-section-content-avvisi-jsx" */),
  "component---src-templates-section-content-dedicato-jsx": () => import("./../../../src/templates/sectionContentDedicato.jsx" /* webpackChunkName: "component---src-templates-section-content-dedicato-jsx" */),
  "component---src-templates-section-content-jsx": () => import("./../../../src/templates/sectionContent.jsx" /* webpackChunkName: "component---src-templates-section-content-jsx" */),
  "component---src-templates-section-content-list-jsx": () => import("./../../../src/templates/sectionContentList.jsx" /* webpackChunkName: "component---src-templates-section-content-list-jsx" */),
  "component---src-templates-section-content-simple-archivio-report-jsx": () => import("./../../../src/templates/sectionContentSimpleArchivioReport.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-archivio-report-jsx" */),
  "component---src-templates-section-content-simple-banche-dati-jsx": () => import("./../../../src/templates/sectionContentSimpleBancheDati.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-banche-dati-jsx" */),
  "component---src-templates-section-content-simple-bandi-jsx": () => import("./../../../src/templates/sectionContentSimpleBandi.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-bandi-jsx" */),
  "component---src-templates-section-content-simple-corsi-jsx": () => import("./../../../src/templates/sectionContentSimpleCorsi.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-corsi-jsx" */),
  "component---src-templates-section-content-simple-highlights-jsx": () => import("./../../../src/templates/sectionContentSimpleHighlights.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-highlights-jsx" */),
  "component---src-templates-section-content-simple-normativa-jsx": () => import("./../../../src/templates/sectionContentSimpleNormativa.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-normativa-jsx" */),
  "component---src-templates-section-content-simple-servizi-online-jsx": () => import("./../../../src/templates/sectionContentSimpleServiziOnline.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-servizi-online-jsx" */),
  "component---src-templates-section-content-simple-sistemi-informativi-jsx": () => import("./../../../src/templates/sectionContentSimpleSistemiInformativi.jsx" /* webpackChunkName: "component---src-templates-section-content-simple-sistemi-informativi-jsx" */),
  "component---src-templates-section-home-jsx": () => import("./../../../src/templates/sectionHome.jsx" /* webpackChunkName: "component---src-templates-section-home-jsx" */),
  "component---src-templates-section-tabs-jsx": () => import("./../../../src/templates/sectionTabs.jsx" /* webpackChunkName: "component---src-templates-section-tabs-jsx" */),
  "component---src-templates-section-tema-jsx": () => import("./../../../src/templates/sectionTema.jsx" /* webpackChunkName: "component---src-templates-section-tema-jsx" */),
  "component---src-templates-servizio-territorio-jsx": () => import("./../../../src/templates/servizioTerritorio.jsx" /* webpackChunkName: "component---src-templates-servizio-territorio-jsx" */),
  "component---src-templates-sistema-informativo-jsx": () => import("./../../../src/templates/sistemaInformativo.jsx" /* webpackChunkName: "component---src-templates-sistema-informativo-jsx" */),
  "component---src-templates-social-jsx": () => import("./../../../src/templates/social.jsx" /* webpackChunkName: "component---src-templates-social-jsx" */),
  "component---src-templates-strutture-sanitarie-jsx": () => import("./../../../src/templates/struttureSanitarie.jsx" /* webpackChunkName: "component---src-templates-strutture-sanitarie-jsx" */),
  "component---src-templates-tema-detail-jsx": () => import("./../../../src/templates/temaDetail.jsx" /* webpackChunkName: "component---src-templates-tema-detail-jsx" */),
  "component---src-templates-temi-az-jsx": () => import("./../../../src/templates/temiAZ.jsx" /* webpackChunkName: "component---src-templates-temi-az-jsx" */),
  "component---src-templates-uffici-lista-jsx": () => import("./../../../src/templates/ufficiLista.jsx" /* webpackChunkName: "component---src-templates-uffici-lista-jsx" */),
  "component---src-templates-ufficio-jsx": () => import("./../../../src/templates/ufficio.jsx" /* webpackChunkName: "component---src-templates-ufficio-jsx" */),
  "component---src-templates-un-po-di-storia-jsx": () => import("./../../../src/templates/unPoDiStoria.jsx" /* webpackChunkName: "component---src-templates-un-po-di-storia-jsx" */)
}

