import { useStaticQuery, graphql } from 'gatsby';

const useThemes = () => {
  const { allNodeSectionTema } = useStaticQuery(graphql`
    query {
      allNodeSectionTema {
        nodes {
          relationships {
            field_temi {
              name
            }
          }
          path {
            langcode
            alias
          }
          field_custom_menu_01
        }
      }
    }
  `);
  return allNodeSectionTema.nodes;
};

export default useThemes;
