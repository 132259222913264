import { useStaticQuery, graphql } from 'gatsby';

const useAllAliasPath = () => {
  const { allPathAliasPathAlias } = useStaticQuery(graphql`
    {
      allPathAliasPathAlias {
        nodes {
          alias
          path
          langcode
        }
        totalCount
      }
    }
  `);
  return allPathAliasPathAlias.nodes;
};

export default useAllAliasPath;
