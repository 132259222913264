import { useStaticQuery, graphql } from 'gatsby';

const useLowerFooter = () => {
  const { allBlockContentBasic } = useStaticQuery(graphql`
    query BlockContentBasic {
      allBlockContentBasic {
        edges {
          node {
            id
            body {
              processed
            }
            field_place
          }
        }
      }
    }
  `);
  return allBlockContentBasic.edges;
};

export default useLowerFooter;
