import React from 'react';
import { domToReact } from 'html-react-parser';
import parse from '@src/utils/parse';
import { debounce } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { get, isNull, isString } from 'lodash';
import { navigate } from 'gatsby';

/**
 * Function that returns the children of the parent page, starting from the menu query
 * @param {*} array
 * @param {*} drupal_internal__nid
 *
 */

function dinamicLinks(array, drupal_internal__nid = '') {
  return array
    .reduce((result, el) => [...result, ...el.childMenuLinkContent], [])
    .reduce(
      (result, el) => [
        ...result,
        ...(el.childMenuLinkContent.filter(
          (child) =>
            Number(child.route.parameters.node) === drupal_internal__nid
        ).length > 0
          ? el.childMenuLinkContent
          : [])
      ],
      []
    )
    .filter((el) => Number(el.route.parameters.node) !== drupal_internal__nid);
}

/**
 * Map data single post
 * @param {*} news
 */

function mapDataNewsMedia(news) {
  const {
    title = '',
    body,
    field_data_pubblicazione,
    field_image: { alt = '' } = {},
    relationships: { field_image = null, field_video_remote = null } = {},
    path: { alias, langcode } = {}
  } = news || {};

  if (field_image) {
    return {
      title,
      body,
      field_data_pubblicazione,
      category: 'Notizie',
      image: { data: field_image, alt: alt },
      path: `/${langcode}${alias}`,
      text: (body && body.processed) || ''
    };
  }
  if (
    field_video_remote &&
    field_video_remote.length > 0 &&
    field_video_remote[0].relationships &&
    field_video_remote[0].relationships.thumbnail
  ) {
    return {
      title,
      body,
      field_data_pubblicazione,
      category: 'Video',
      image: {
        data: field_video_remote[0].relationships.thumbnail,
        alt: field_video_remote[0].relationships.thumbnail.filename
      },
      path: `/${langcode}${alias}`,
      text: (body && body.processed) || ''
    };
  }
  return {
    title,
    body,
    field_data_pubblicazione,
    category: 'Notizie',
    image: {
      src: '',
      alt: ''
    },
    path: `/${langcode}${alias}`,
    text: (body && body.processed) || ''
  };
}
/**
 * Function that allows you to clean the html from script tags
 * @param {*} html
 */

function sanizeHtml(html) {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe']),
    allowedAttributes: false,
    allowedIframeHostnames: [
      'www.youtube.com',
      'player.vimeo.com',
      'www.youtube-nocookie.com'
    ]
  });
}

/**
 * Function that allows you to retrieve the value of an attribute in an object and check if it is null
 * @param {*} object
 * @param {*} path
 * @param {*} defaultValue
 */

function getValueNull(object, path, defaultValue) {
  const getValue = get(
    object,
    isString(path) && path !== '' ? path : '',
    defaultValue
  );
  return getValue !== null ? getValue : defaultValue;
}

/**
 * Function that allows you to create an alias starting from the node_type and from the drupal_internal__vid
 * @param {*} data
 * @param {*} path
 */

function getPathAlias(data, path) {
  return data.map((el) => {
    if (!el.path.alias || isNull(el.path.alias)) {
      return {
        ...el,
        path: {
          ...el.path,
          alias: `/${data
            .reduce((result, el) => `${getValueNull(el, path, '')}`)
            .toLowerCase()}/${el.drupal_internal__nid}`
        }
      };
    }
    return el;
  });
}

/**
 * Function that allows you to retrieve the alias of a page starting from a Drupal URL
 * @param {*} allAliasPath --> Array of all page aliases
 * @param {*} searchPath --> Path to search for, for example entity:node/44856
 * @return --> for example /it/sezione/notizie
 */

function searchAlias(allAliasPath, searchPath) {
  if ((searchPath || '').includes('internal:')) {
    return (searchPath || '').replace('internal:', '');
  }
  const nodeAliasEntity = allAliasPath.filter(
    (el) => el.path === (searchPath || '').replace('entity:', '/')
  );

  if (nodeAliasEntity.length > 0) {
    return `/${nodeAliasEntity[0].langcode}${nodeAliasEntity[0].alias}`;
  }
  return `/`;
}

/**
 * Function that allows you to retrieve the related links from a page
 * @param {str} string --> string to capitalize
 *
 * @return {string} --> Array of related links
 * */
export const capitalizeFirstLetter = (str) =>
  String(str).charAt(0).toUpperCase() + String(str).slice(1).toLowerCase();

/**
 * Function that allows you to parse a string and return an array of objects
 * @param {path} text --> path *
 * @return {VoidFunction}
 */
export const delayedNavigation = debounce((path) => {
  navigate(path);
}, 2000);
export const delayedNavigation500 = debounce((path) => {
  navigate(path);
}, 500);
/**
 * Function that allows you to open in a new tab external link
 * @param {url} text --> path *
 * @return {VoidFunction}
 */
export const openExternalLink = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

/**
 * Function that cleans elements inside an Html block from Drupal CKEditor
 * @param {string} html --> Html to transform
 * @return {string} --> Html transformed
 */
function cleanHtmlBlock(html) {
  const options = {
    replace: ({ attribs, children, prev, name }) => {
      if (!attribs) {
        return;
      }

      // Accordion --> replace a definition list accordion with a Bootstrap Italia one
      if (name === 'dl') {
        return (
          <div
            className='accordion accordion-left-icon mt-5'
            id='accordionExampleLft'
          >
            <div className='accordion-item'>
              {domToReact(children, options)}
            </div>
          </div>
        );
      }

      if (name === 'dt') {
        return (
          <h2
            className='accordion-header'
            id={`heading${children[0].data}`
              .replace(/[^a-zA-Z0-9 ]/g, '')
              .replace(/\s/g, '-')
              .toLowerCase()}
          >
            <button
              className='accordion-button collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={
                '#' +
                `collapse${children[0].data}`
                  .replace(/[^a-zA-Z0-9 ]/g, '')
                  .replace(/\s/g, '-')
                  .toLowerCase()
              }
              aria-expanded='false'
              aria-controls={`collapse${children[0]?.data}`
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .replace(/\s/g, '-')
                .toLowerCase()}
            >
              {domToReact(children, options)}
            </button>
          </h2>
        );
      }

      if (prev?.children && name === 'dd') {
        return (
          <div
            id={`collapse${prev?.children[0]?.data}`
              .replace(/[^a-zA-Z0-9 ]/g, '')
              .replace(/\s/g, '-')
              .toLowerCase()}
            className='accordion-collapse collapse'
            data-bs-parent='#accordionExampleLft'
            role='region'
            aria-labelledby={`heading${prev?.children[0]?.data}`
              .replace(/[^a-zA-Z0-9 ]/g, '')
              .replace(/\s/g, '-')
              .toLowerCase()}
          >
            <div className='accordion-body'>
              {domToReact(children, options)}
            </div>
          </div>
        );
      }

      // Oembed --> replace an oembed element with an iframe element (for Youtube embedding)
      if (name === 'figure') {
        return (
          <div className='ratio ratio-16x9'>
            <iframe
              className='embed-responsive-item'
              src={children[0].attribs.url
                .replace('www.youtube.com', 'www.youtube-nocookie.com')
                .replace('watch', 'embed')
                .replace('v=', '')
                .replace('?', '/')}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </div>
        );
      }

      // Columns
      if (attribs.class === 'ck-widget ck-widget bs_grid') {
        return <div>{domToReact(children, options)}</div>;
      }

      if (attribs.class === 'ck-widget ck-widget row') {
        children.forEach((div) => {
          div.children.forEach((el) => {
            if (el.name === 'img') {
              el.attribs.style = 'width: 100%;';
            }
            el.children?.forEach((item) => {
              if (item.name === 'img') {
                item.attribs.style = 'width: 100%;';
              }
            });
          });
        });

        const columnsNumber = 12 / children.length;
        children.map((div) => (div.attribs.class = `col-${columnsNumber}`));
        return (
          <div className='row mt-5 mb-5'>{domToReact(children, options)}</div>
        );
      }

      // Tables
      if (name === 'table') {
        return (
          <div className='table-responsive'>
            <table className='table'>{domToReact(children, options)}</table>
          </div>
        );
      }

      if (name === 'thead') {
        if (children) {
          children.forEach((el) =>
            el.type === 'tag'
              ? (el.attribs.style = 'border-bottom-width: initial;')
              : ''
          );
        }
      }

      //Remove contentEditable attribute
      if (attribs.class === 'ck-widget ck-widget container-fluid') {
        return (
          <div className='ck-widget ck-widget container-fluid'>
            {domToReact(children, options)}
          </div>
        );
      }

      if (attribs.class === 'ck-widget ck-widget row no-gutters g-0') {
        return (
          <div className='ck-widget ck-widget row no-gutters g-0'>
            {domToReact(children, options)}
          </div>
        );
      }

      if (attribs.class === 'ck-widget ck-widget bs_grid ') {
        return (
          <div className='ck-widget ck-widget bs_grid'>
            {domToReact(children, options)}
          </div>
        );
      }
    }
  };
  return parse(html ? html : '', options);
}

/**
 * @param {*} hrefSocial
 * @param {*} urlPage
 */

function shareSocialLink(hrefSocial, urlPage) {
  return `${hrefSocial}${encodeURIComponent(urlPage)}`;
}

/**
 *
 * @param {*} children //React element
 * @returns string
 */

function extractText(children) {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type && typeof child.type === 'string') {
        if (child.type.startsWith('svg') || child.type === 'img') {
          return '';
        }
      }
      return extractText(child.props.children);
    } else if (typeof child === 'string') {
      return child;
    }
    return '';
  });
}
/**
 *
 * @param {*} {dateInString} //example '1978/3/23'
 * @param {*} monthsInWords //example ['janaury', 'febraury', ....]
 * @returns string
 */
function correctTheDate(dateInString, monthsInWords) {
  let dateInArray = null;
  if (dateInString.length > 4) {
    dateInArray = dateInString.split('-');
    if (dateInArray[0].length === 4 && dateInArray[2] > 0 && dateInArray[1] > 0)
      return (
        dateInArray[2].replace(/^0+/, '') +
        ' ' +
        `${monthsInWords[dateInArray[1] - 1]}` +
        ' ' +
        dateInArray[0]
      );
  } else {
    return '';
  }
}

export {
  dinamicLinks,
  mapDataNewsMedia,
  sanizeHtml,
  getValueNull,
  getPathAlias,
  searchAlias,
  cleanHtmlBlock,
  shareSocialLink,
  extractText,
  correctTheDate
};
