import { useStaticQuery, graphql } from 'gatsby';

const useMegamenu = () => {
  const {
    sectionMenu: { nodes }
  } = useStaticQuery(
    graphql`
      fragment MenuContent on menu_link_content__menu_link_content {
        id
        title
        url
        enabled
        options {
          external
          attributes {
            to_side
          }
        }
        route {
          parameters {
            node
          }
        }
      }

      {
        sectionMenu: allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: { eq: "section-menu" }
            drupal_parent_menu_item: { eq: "" }
          }
        ) {
          nodes {
            ...MenuContent
            childMenuLinkContent {
              ...MenuContent
              childMenuLinkContent {
                ...MenuContent
              }
            }
          }
        }
      }
    `
  );
  return nodes;
};

export default useMegamenu;
