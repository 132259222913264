import React from 'react';

// css splide - carousel
import '@splidejs/react-splide/css';

import 'bootstrap-italia/dist/bootstrap-italia.esm';
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css';

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';

import '@styles/global.scss';

import Layout from '@components/Layout';

import browserHydrateFunction from './src/utils/browser-hydrate-function';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location);
  if (location.state?.preventScrollReset) return false;
  setTimeout(() => {
    if (!window) return;
    if (location.hash && location.hash !== '') {
      const node = document.querySelector(location.hash);
      if (node) {
        document.querySelector(location.hash)?.scrollIntoView();
        return;
      }
    }
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);
  return false;
};

export const replaceHydrateFunction = browserHydrateFunction;
