import React, { useMemo, useState } from 'react';
import { useStaticQuery, navigate, graphql } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';

import useThemes from '@hooks/useThemes';
import LetterButton from '@components/LetterButton';

const TemiAZLetters = ({ dark = false, preventScrollReset = false, isGlossario = false }) => {
  const themes = useThemes();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const selectedLetter = params.get('letter');

  const activeLetterGlossario = pathname === '/it/tema/antibiotico-resistenza/glossario/' ? 'a' : pathname.split('-').pop().replace('/', '');

  const { temiAZ, glossarioAZ } = useStaticQuery(graphql`
query TemiAZPage {
  temiAZ: nodePage(field_gatsby_template: {eq: "temiAZ"}) {
    path {
      alias
      langcode
    }
  }
  glossarioAZ: allNodePage(
    filter: {relationships: {field_temi: {elemMatch: {name: {eq: "Antibiotico-resistenza"}}}}}
  ) {
    nodes {
      path {
        alias
        langcode
      }
    }
  }
}
  `);


  const letterTabs = useMemo(
    () => [
      {
        letter: '0-9',
        disabled: themes?.every((theme) =>
          isNaN(theme?.relationships?.field_temi[0]?.name?.charAt(0))
        )
      },
      ...[...Array(26).keys()]
        .map((n) => String.fromCharCode(97 + n))
        .map((l) => ({
          letter: l,
          disabled: !themes?.some(
            (theme) =>
              l ===
              theme?.relationships?.field_temi[0]?.name?.charAt(0).toLowerCase()
          )
        }))
    ],
    [themes]
  );

  const validLettersGlossario = useMemo(() => {
    const validLetters = glossarioAZ?.nodes
      ?.filter(node => node?.path?.alias?.includes('/tema/antibiotico-resistenza/glossario-'))
      .map(node => node?.path?.alias?.split('-').pop()) || [];
    const hasLetterA = glossarioAZ?.nodes.some(node => node?.path?.alias === '/tema/antibiotico-resistenza/glossario');
    if (hasLetterA) {
      validLetters.push('a');
    }
    return validLetters;
  }, [glossarioAZ, isGlossario]);

  const letterTabsGlossario = useMemo(
    () => [...Array(26).keys()].map(n => {
      const letter = String.fromCharCode(97 + n);
      return {
        letter,
        active: letter === activeLetterGlossario,
        disabled: !validLettersGlossario.includes(letter),
      };
    }),
    [validLettersGlossario]
  );

  const onLetterClick = (letter) => {
    if (isGlossario) {
      const nodeLetterA = glossarioAZ?.nodes?.find(node => node?.path?.alias === '/tema/antibiotico-resistenza/glossario');
      const nodeForLetter = glossarioAZ?.nodes?.find(node => node?.path?.alias?.split('-').pop() === letter);
      if (nodeForLetter) {
        const aliasWithoutLastLetter = nodeForLetter.path?.alias?.split('-').slice(0, -1).join('-');
        const newUrl = `/${nodeForLetter.path?.langcode}${aliasWithoutLastLetter}-${letter}`;
        return navigate(newUrl, { state: { preventScrollReset } });
      }else if (nodeLetterA) {
        const newUrl = `/${nodeLetterA.path?.langcode}${nodeLetterA.path?.alias}`;
        return navigate(newUrl, { state: { preventScrollReset } });
      }
    } else {
      return navigate(
        `/${temiAZ?.path?.langcode}${temiAZ.path.alias}?letter=${letter}`,
        { state: { preventScrollReset } }
      );
    }
  };

  return (
    <div className='d-flex flex-row flex-wrap gap-2'>
      {isGlossario ? letterTabsGlossario.map(({ letter, disabled, active }, index) => (
        <LetterButton
          key={index}
          letter={letter}
          disabled={disabled}
          active={active}
          dark={dark}
          onClick={() => onLetterClick(letter)}
        />
      )) : letterTabs.map(({ letter, disabled }, index) => (
        <LetterButton
          key={index}
          letter={letter}
          disabled={disabled}
          active={selectedLetter === letter}
          dark={dark}
          onClick={() => onLetterClick(letter)}
        />
      ))}
    </div>
  );
};

export default TemiAZLetters;
