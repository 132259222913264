import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const LetterButton = ({
  letter,
  dark,
  active,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <StyledLetterButton
      dark={dark}
      active={active}
      disabled={disabled}
      tabIndex={'0'}
      onClick={disabled ? null : onClick}
      className={classNames('border border-2 rounded py-1 px-0 ', {
        'neutral-1-border-color-a2 neutral-2-color-a3': disabled,
        'primary-color primary-border-color': !disabled && !dark,
        'analogue-2-color-a3 analogue-2-border-color-a3': !disabled && dark,
        'primary-border-color-a10 primary-bg-a10 primary-color-a10':
          active && !dark,
        'analogue-2-bg-a3': active && dark
      })}
      {...props}
    >
      <span className={classNames('fw-bold', {})}>{letter.toUpperCase()}</span>
    </StyledLetterButton>
  );
};

const StyledLetterButton = styled.button`
  width: 46px !important;
  height: 40px !important;
  text-decoration: none;
  background-color: transparent;
  & > span {
    color: ${({ active }) => active && '#ffffff'}!important;
  }
`;
export default LetterButton;
