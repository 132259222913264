import { useStaticQuery, graphql } from 'gatsby';

const useUpperFooter = () => {
  const { footer01 } = useStaticQuery(graphql`
    fragment MenuContentFooter on menu_link_content__menu_link_content {
      id
      title
      url
    }

    {
      footer01: allMenuLinkContentMenuLinkContent(
        filter: {
          menu_name: { eq: "footer01" }
          drupal_parent_menu_item: { eq: "" }
        }
      ) {
        nodes {
          ...MenuContentFooter
          childMenuLinkContent {
            ...MenuContentFooter
            childMenuLinkContent {
              ...MenuContentFooter
            }
          }
        }
      }
    }
  `);
  return footer01.nodes;
};

export default useUpperFooter;
