import React from 'react';
import Link from '@components/Link';
import styled from 'styled-components';
import { useEffect } from 'react';
import BIIcon from '@components/BIIcon';
import useMegamenu from '@hooks/useMegamenu';
import { useLocation } from '@gatsbyjs/reach-router';
const Megamenu = ({ mobileVersion }) => {
  const data = useMegamenu();

  const location = useLocation();

  const closeButtonId = mobileVersion
    ? 'button-to-close-mobile'
    : 'button-to-close-desktop';

  useEffect(() => {
    const btnClose = document.getElementById(closeButtonId);
    if (!!btnClose) btnClose.click();
    /*
    const menu = document.getElementById('nav-megamenu');
    if (menu) {
      menu.style.display = 'none';
      menu.setAttribute('aria-expanded', 'false');
      menu.classList.remove('expanded');
    }

    const overlay = document.querySelector('.overlay');
    if (overlay) {
      overlay.style.display = 'none';
    }*/
  }, [location.pathname, closeButtonId]);

  return (
    <nav className='navbar navbar-expand-lg has-megamenu'>
      <button
        className='custom-navbar-toggler'
        type='button'
        aria-controls={mobileVersion ? 'nav-megamenu-mobile' : 'nav-megamenu'}
        aria-expanded='false'
        aria-label={`Mostra/Nascondi la navigazione${
          mobileVersion ? ' mobile' : ''
        }`}
        data-bs-toggle='navbarcollapsible'
        data-bs-target={
          mobileVersion ? '#nav-megamenu-mobile' : '#nav-megamenu'
        }
      >
        <BIIcon name='burger' size='m' />
      </button>
      <div
        className='navbar-collapsable'
        id={mobileVersion ? 'nav-megamenu-mobile' : 'nav-megamenu'}
      >
        <div className='overlay'></div>
        <div className='close-div'>
          <button className='btn close-menu' type='button' id={closeButtonId}>
            <span className='visually-hidden'>Nascondi la navigazione</span>
            <BIIcon name='close-big' size='m' />
          </button>
        </div>
        <div className='menu-wrapper justify-content-center'>
          <NavBar className='navbar-nav'>
            {data.map(({ id, title, childMenuLinkContent }) => {
              const actions = childMenuLinkContent.filter(
                (e) =>
                  e.options?.attributes?.to_side &&
                  !!e.options.attributes.to_side
              );
              const categories = childMenuLinkContent.filter(
                (e) => !e.options?.attributes?.to_side
              );
              return (
                <li key={id} className='nav-item dropdown megamenu'>
                  <NavLink
                    className='nav-link dropdown-toggle'
                    href='dropdown'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    aria-label={`Home Menu${
                      mobileVersion ? ' mobile' : ''
                    }. Apri dettaglio su ${title}`}
                  >
                    <span className='text-nowrap'>{title}</span>
                    <BottomChevron name='expand' />
                  </NavLink>
                  <div
                    className='dropdown-menu'
                    role='region'
                    style={{ padding: '16px 24px' }}
                  >
                    <div className='row'>
                      <div
                        className={
                          categories.length === 3
                            ? 'col-12 col-lg-9'
                            : categories.length === 2
                            ? 'col-12 col-lg-8'
                            : 'fourOfFiveColumns'
                        }
                      >
                        <div className='row'>
                          {categories.map(
                            ({ id, title, url, childMenuLinkContent }) => (
                              <div
                                key={id}
                                className={
                                  categories.length === 3
                                    ? 'col-12 col-lg-4 p-0'
                                    : categories.length === 2
                                    ? 'col-12 col-lg-6 p-0'
                                    : 'col-12 col-lg-3 p-0'
                                }
                                style={{ gap: '16px' }}
                              >
                                <div className='link-list-wrapper'>
                                  <Link
                                    className='link-list-heading text-secondary fs-6 mt-1 mt-lg-3 d-block column-heading-link'
                                    to={url}
                                    aria-label={`Apri collegamento a ${title}`}
                                    onClick={() => {
                                      document
                                        .getElementById(closeButtonId)
                                        .click();
                                    }}
                                  >
                                    {title}
                                  </Link>

                                  <ul className='link-list d-none d-lg-block'>
                                    {childMenuLinkContent.map(
                                      ({ id, title, url }) => (
                                        <li key={id}>
                                          <Link
                                            className='dropdown-item list-item px-0'
                                            to={url}
                                            target={
                                              !url?.includes('http')
                                                ? '_self'
                                                : '_blank'
                                            }
                                            aria-label={
                                              !url?.includes('http')
                                                ? `${title}${
                                                    mobileVersion
                                                      ? '_versione_mobile'
                                                      : ''
                                                  } `
                                                : `${title} - Sito esterno - Apertura in una nuova finestra${
                                                    mobileVersion
                                                      ? '_versione_mobile'
                                                      : ''
                                                  }`
                                            }
                                          >
                                            <span className='ps-2'>
                                              {title}
                                            </span>
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          categories.length === 3
                            ? 'col-12 col-lg-3'
                            : categories.length === 2
                            ? 'col-12 col-lg-4'
                            : 'oneOfFiveColumns'
                        }
                      >
                        <div className='row'>
                          <div className='col-12'>
                            <div
                              className='big-screen'
                              style={{ height: '24px' }}
                            ></div>
                            <div
                              className='small-screen'
                              style={{ height: '16px' }}
                            ></div>
                            <div className='link-list-wrapper mt-0 mt-lg-4 ps-0'>
                              <ul className='link-list '>
                                {actions.map(({ id, title, url }) => (
                                  <Action
                                    key={id}
                                    className='border-bottom'
                                    style={{
                                      fontSize: '16px',
                                      lineHeight: '1.5'
                                    }}
                                  >
                                    <ActionLink
                                      className='medium'
                                      style={{
                                        fontSize: '16px'
                                      }}
                                      to={url}
                                      onClick={() => {
                                        document
                                          .getElementById(closeButtonId)
                                          .click();
                                      }}
                                      target={
                                        !url?.includes('http')
                                          ? '_self'
                                          : '_blank'
                                      }
                                      aria-label={
                                        !url?.includes('http')
                                          ? `${title}${
                                              mobileVersion
                                                ? '_versione_mobile'
                                                : ''
                                            }`
                                          : `${title} - Sito esterno - Apertura in una nuova finestra${
                                              mobileVersion
                                                ? '_versione_mobile'
                                                : ''
                                            }`
                                      }
                                    >
                                      <span className='me-0'>{title}</span>
                                    </ActionLink>
                                    <BIIcon
                                      name='chevron-right'
                                      size='m'
                                      color='primary'
                                    />
                                  </Action>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </NavBar>
        </div>
      </div>
    </nav>
  );
};

const NavBar = styled.ul`
  overflow-x: auto !important;
  overflow-y: clip !important;
`;

const NavLink = styled.a`
  line-height: 100%;
  white-space: normal !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
`;

const BottomChevron = styled(BIIcon)`
  min-width: 18px;
  align-self: flex-end;
  margin-left: 4px;
`;

const Action = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionLink = styled(Link)`
  display: flex !important;
  padding: 16px 0px 16px 8px !important;
`;

export default Megamenu;
