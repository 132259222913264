import React, { useEffect } from 'react';

import Header from '@components/Header';
import Footer from '@components/Footer';
import HtmlComment from '@components/HtmlComment';
import BackToTop from '../BackToTop';

const Layout = ({ children }) => {
  //set the Language Attribute for the html tag
  useEffect(() => {
    document.documentElement.lang = 'it';

    if (window.location.hash === '#main-content') {
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, []);

  return (
    <>
      <a href='#main-content' className='skip-link'>
        Vai direttamente al contenuto
      </a>
      <HtmlComment text={'googleoff: all'} />
      <Header />
      <HtmlComment text={'googleon: all'} />
      <main id='main-content'>{children}</main>
      <HtmlComment text={'googleoff: all'} />
      <Footer />
      <HtmlComment text={'googleon: all'} />
      <BackToTop />
    </>
  );
};

export default Layout;
