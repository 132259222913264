import Link from '@components/Link';
import React from 'react';

const ColonnaFooter = ({
  column: { title = '', url = '', childMenuLinkContent = [] }
}) => {
  return (
    <div className='col-12 col-lg-3 col-md-6 my-3 my-lg-0'>
      <div className='h4 mb-3 fontSize18'>{title}</div>
      <div>
        {childMenuLinkContent.map(({ url, title, id }) => (
          <React.Fragment key={id}>
            <p className='mb-2'>
              <Link
                to={url}
                className='text-white '
                aria-label={`${
                  typeof url === 'string' &&
                  url.length > 0 &&
                  url.startsWith('http')
                    ? title + ' - Sito esterno - Apertura in una nuova finestra'
                    : ' Collegamento a ' + title
                }`}
              >
                {title}
              </Link>
            </p>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ColonnaFooter;
