import { useState, useCallback } from 'react';
import config from '@root/quelix.config';

const buildRequiredFields = (filter = []) => {
  const ORConditions = filter
    .map((object) => {
      const differentMetaANDConditions = Object.keys(object).map((meta) => {
        const sameMetaANDConditions = (
          Array.isArray(object[meta]) ? object[meta] : [object[meta]]
        ).map((value) => `${encodeURIComponent(meta)}:${value}`);
        return sameMetaANDConditions.join('.');
      });
      return differentMetaANDConditions.join('.');
    })
    .filter((o) => Object.keys(o).length > 0);
  return ORConditions.map((list, _, conditions) =>
    conditions.length > 1 ? `(${list})` : list
  ).join('|');
};

const buildParams = ({ q, requiredfields, ...params }) => ({
  getfields: '*',
  num: 100,
  ...params,
  requiredfields: [buildRequiredFields(config?.filter ?? [])]
    .concat([requiredfields])
    .filter((array) => array.length > 0)
    .map((list) => `(${list})`)
    .join('.'),
  site: config.site,
  client: config.client,
  proxystylesheet: 'json',
  q: `${config?.defaultQuery ?? ''} ${q}`
});

const request = async (params, options) => {
  const url = `${config.url}?${new URLSearchParams(params)}`;
  const request = await fetch(url, { ...options });
  return await request.json();
};

/**
 * React hook for Quelix search engine.
 *
 * All the defined filters in the array are joined with the OR operator.
 * Instead, all the conditions defined in each filter are joined with the AND operator.
 *
 * @param {Object} config - Hook configuration.
 * @param {Object} config.params - Customize API requests params.
 * @param {Object[]} config.filter - Apply metatag filter to results.
 * @example
 * const [results, search] = useQuelixSearch({
 *  params: { num: 10 },   // Limit search to 10 results.
 *  filter: [
 *    {
 *      // Include results with metatag 'gatsby:theme' NOT
 *      // equal 'ThemeA' AND 'ThemeB'
 *      // (because of the minus (-) before the tag)
 *      // AND metatag 'gatsby:node-type' equals 'Type A'
 *      '-gatsby:theme': [ 'ThemeA', 'ThemeB' ],
 *      'gatsby:node-type': 'Type A'
 *    },
 *    // OR
 *    { 'gatsby:node-type': 'Type B' }
 *  ]
 * });
 */
const useQuelixSearch = (config = { params: {}, filter: [] }) => {
  const { params: customParams, filter } = config;
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [count, setCount] = useState(0);
  const search = useCallback(
    (query) => {
      const controller = new AbortController();
      const requiredfields = buildRequiredFields(filter);
      const params = buildParams({ q: query, requiredfields, ...customParams });
      setLoading(true);
      setResults([]);
      request(params, { signal: controller.signal })
        .then((json) => {
          setCount(json['GSP']['RES']?.['M'] ?? 0);
          setResults(json['GSP']['RES']?.['R'] ?? []);
        })
        .catch((error) => error.name !== 'AbortError' && console.error(error))
        .finally(() => setLoading(false));
      return () => controller.abort('');
    },
    [customParams, filter]
  );
  return { search, loading, count, results };
};

export default useQuelixSearch;
