import React from 'react';
import HTMLReactParser, { domToReact } from 'html-react-parser';
import { join } from 'path-browserify';
import Link from '@components/Link';

const options = {
  replace: ({ name, attribs, children }) => {
    if (!attribs) return;
    if (name === 'li' && attribs?.class === 'brick-shift-1') {
      return <li className='ms-4'>{domToReact(children)}</li>;
    }
    if (name === 'li' && attribs?.class === 'brick-shift-2') {
      return <li className='ms-5'>{domToReact(children)}</li>;
    }
    if (name === 'img') {
      if (!!attribs?.style) {
        const { style, ...props } = attribs;
        return (
          <img
            {...props}
            css={attribs.style}
            src={
              !!attribs.src
                ? attribs.src?.includes('http')
                  ? attribs.src
                  : join(process.env.GATSBY_PREFIX_PATH ?? '', attribs.src)
                : ''
            }
          />
        );
      }

      return (
        <img
          {...attribs}
          src={
            !!attribs.src
              ? attribs.src?.includes('http')
                ? attribs.src
                : join(process.env.GATSBY_PREFIX_PATH ?? '', attribs.src)
              : ''
          }
        />
      );
    }
    if (name === 'a') {
      const className = attribs?.class ?? '';
      const id = attribs?.id ?? '';
      const href = attribs?.href ?? '#';
      const openTarget = href?.startsWith('#') ? '_self' : attribs?.target;
      const ariaLabel = attribs?.['aria-label'] ?? '';
      const title = !!attribs?.title
        ? attribs.title
        : href?.substring(href?.length - 3, href?.length) === 'pdf'
        ? 'Apre PDF'
        : '';

      const dataBsContent = attribs?.['data-bs-content'];
      // const dataBsPlacement = attribs?.['data-bs-placement'];
      // const dataBsToggle = attribs?.['data-bs-toggle'];
      // const dataContainer = attribs?.['data-container'];

      if (className === 'fade show') {
        return (
          <>
            <button
              popovertarget={title}
              popovertargetaction='toggle'
              id='menu-toggle'
            >
              {domToReact(
                children.map((c) => {
                  if (c?.name === 'img') {
                    if (!!c?.attribs?.style) {
                      return {
                        ...c,
                        css: c.attribs.style,
                        attribs: {
                          ...c.attribs,
                          src: !!c?.attribs.src
                            ? c.attribs.src?.includes('http')
                              ? c.attribs.src
                              : join(
                                  process.env.GATSBY_PREFIX_PATH ?? '',
                                  c?.attribs.src
                                )
                            : ''
                        }
                      };
                    }
                    return {
                      ...c,
                      attribs: {
                        ...c.attribs,
                        src: !!c?.attribs.src
                          ? c.attribs.src?.includes('http')
                            ? c.attribs.src
                            : join(
                                process.env.GATSBY_PREFIX_PATH ?? '',
                                c?.attribs.src
                              )
                          : ''
                      }
                    };
                  } else return c;
                })
              )}
            </button>
            <div anchor='menu-toggle' id={title} popover='true'>
              {dataBsContent}
            </div>
          </>
        );
      }

      if (className === 'ancora') {
        return (
          <span id={attribs?.id ?? attribs?.name} className='ancora'>
            {domToReact(children)}
          </span>
        );
      }

      if (!!attribs?.name)
        return (
          <Link
            to={href}
            className={'text-reset text-decoration-none'}
            title={title}
            target={openTarget}
            name={attribs.name}
            aria-label={ariaLabel}
          >
            {domToReact(
              children.map((c) => {
                if (c?.name === 'img') {
                  if (!!c?.attribs?.style) {
                    return {
                      ...c,
                      css: c.attribs.style,
                      attribs: {
                        ...c.attribs,
                        src: !!c?.attribs.src
                          ? c.attribs.src?.includes('http')
                            ? c.attribs.src
                            : join(
                                process.env.GATSBY_PREFIX_PATH ?? '',
                                c?.attribs.src
                              )
                          : ''
                      }
                    };
                  }
                  return {
                    ...c,
                    attribs: {
                      ...c.attribs,
                      src: !!c?.attribs.src
                        ? c.attribs.src?.includes('http')
                          ? c.attribs.src
                          : join(
                              process.env.GATSBY_PREFIX_PATH ?? '',
                              c?.attribs.src
                            )
                        : ''
                    }
                  };
                } else return c;
              })
            )}
          </Link>
        );

      return (
        <Link
          to={href}
          className={className}
          title={title}
          target={openTarget}
          id={id}
          aria-label={ariaLabel}
        >
          {domToReact(
            children.map((c) => {
              if (c?.name === 'img') {
                if (!!c?.attribs?.style) {
                  return {
                    ...c.attribs,
                    src: !!c?.attribs.src
                      ? c.attribs.src?.includes('http')
                        ? c.attribs.src
                        : join(
                            process.env.GATSBY_PREFIX_PATH ?? '',
                            c?.attribs.src
                          )
                      : ''
                  };
                }
                return {
                  ...c,
                  attribs: {
                    ...c.attribs,
                    src: !!c?.attribs.src
                      ? c.attribs.src?.includes('http')
                        ? c.attribs.src
                        : join(
                            process.env.GATSBY_PREFIX_PATH ?? '',
                            c?.attribs.src
                          )
                      : ''
                  }
                };
              } else return c;
            })
          )}
        </Link>
      );
    }
    if (name === 'table') {
      const rows = children
        ?.find((c) => c?.name === 'tbody')
        ?.children?.filter((el) => el?.name === 'tr');
      const columns = children
        ?.find((c) => c?.name === 'colgroup')
        ?.children?.filter((el) => el?.name === 'col');
      const effectiveNumberOfColumns =
        rows?.length > 0
          ? Math.max(
              ...rows?.map(
                (r) => r?.children?.filter((r) => r?.name === 'td')?.length
              )
            )
          : 0;
      const effectiveChildren = children?.map((c) => {
        return c?.name === 'colgroup'
          ? {
              ...c,
              children:
                columns?.length === effectiveNumberOfColumns
                  ? c?.children
                  : c?.children?.filter(
                      (cc) => columns?.indexOf(cc) < effectiveNumberOfColumns
                    )
            }
          : c;
      });

      return (
        <div className='table-responsive'>
          <table className={(typeof attribs?.class === 'string' &&  attribs?.class.includes('content')) ? `${attribs?.class}` : 'table table-bordered'}>
            {domToReact(effectiveChildren, options)}
          </table>
        </div>
      );
    }
    if (name === 'ul') {
      return (
        <ul style={{ listStylePosition: 'inside' }}>
          {domToReact(children, options)}
        </ul>
      );
    }
  }
};

const parse = (html) => HTMLReactParser(html, options);
export default parse;
